<template>
  <div>
    <PageSection>
      <Breadcrumbs :breadcrumb-items="breadcrumbData" />
      <PageTitle :subtitle="subtitle" title="Lithium">
        <template #actions>
          <div class="flex gap-1 items-center">
            <WeightedUnweightedToggle
              :value="weightedUnweighted"
              @on-change-value="onChangeToggleWeightedUnweighted"
            />
            <WeightedUnweightedHelp />
          </div>
        </template>
        <div
          v-if="hasFactbox"
          class="flex flex-wrap items-start gap-4 border-t-2 border-blue-500 pt-5"
          data-test="factbox"
        >
          <FactBoxTable
            :headers="priceRangeFactbox?.headers || []"
            :data="priceRangeFactbox?.value || []"
          />
          <div
            v-for="(factbox, index) in settingsFactboxV2"
            :key="index"
            class="flex items-start justify-between"
          >
            <FactBoxItem
              :key="index"
              class="py-0 px-0"
              :label="factbox.label"
              :value="factbox.value"
              :label-class="factbox.labelClass"
              :value-class="factbox.valueClass"
              :is-weighted="!!factbox.isWeighted"
            />
          </div>
          <div
            v-for="(factbox, index) in settingsFactboxV1"
            :key="index"
            class="flex items-start justify-between"
          >
            <VerticalDivider class="mr-2" />
            <FactBoxItem
              :key="index"
              class="py-0 pl-2"
              :label="factbox.label"
              :value="factbox.value"
              :label-class="factbox.labelClass"
              :value-class="factbox.valueClass"
            />
          </div>
        </div>
      </PageTitle>
      <Container>
        <tabs-links
          new-styles
          class="!mb-0 mt-1"
          :tabs="tabsLinksData"
          :selected-tab="activeTab"
          active-equal-name
          @switch-active-tab="switchActiveTab"
        />
        <slot />
        <ul class="flex gap-4 pt-4">
          <li v-for="demandScenario in demandScenarios" :key="demandScenario.key">
            <CommonFormRadioButton
              :id="demandScenario.key"
              :data-test="`input-${demandScenario.key}`"
              :label="demandScenario.label"
              name="options"
              variant="secondary"
              :checked="selectedScenario.id === demandScenario.id"
              @change="changeSelectedScenario"
            />
          </li>
        </ul>
        <stepped-loader :loading="pending" />
        <div v-if="!pending" class="card-background mt-3 rounded-lg text-white">
          <div v-if="supplyAndDemandChartData">
            <bar-line
              :key="supplyAndDemandChartData?.key"
              :chart-data="marketSupplyFilteredData"
              :name="supplyAndDemandChartData?.name"
              :title="supplyAndDemandChartData?.title"
              :tooltip-postfix="supplyAndDemandChartData?.tooltipPostfix"
              :y-scale-label="supplyAndDemandChartData?.yScaleLabel"
              :palette="supplyAndDemandChartData?.palette"
              :type="supplyAndDemandChartData?.type"
              :source-data="supplyAndDemandChartData?.sourceData"
              :start-year="DataSourceRange.START_YEAR"
              :end-year="DataSourceRange.END_YEAR"
              :default-start-year="startYear"
              :default-end-year="endYear"
              :disclaimer="activeTab.type === 'chemical' ? disclaimer : ''"
              download-version="v2"
              @change-start-year="onChangeStartYear"
              @change-end-year="onChangeEndYear"
            >
              <template v-if="activeTab.type === 'chemical'" #extraIcons>
                <tabs-with-icons
                  :tabs="childTabs"
                  :active-tab="selectedChildTab"
                  @switch-active-tab="switchActiveChildTab"
                />
              </template>
            </bar-line>
          </div>
          <div v-if="balanceOptions[activeTab?.type]">
            <div v-for="[key, balance] in Object.entries(balanceOptions)" :key="key">
              <balance
                v-if="activeTab?.type === key"
                :key="balance?.key + startYear + endYear"
                :chart-data="balanceFilteredData(balance?.chartData)"
                :name="balance?.name"
                :title="balance?.title"
                :type="balance?.type"
                :units="balance?.units"
                :y-scale-label="balance?.yScaleLabel"
                :palette="balance?.palette"
                :show-toggles="true"
                toggle-size="medium"
                :source-data="balance?.sourceData"
                download-version="v2"
              />
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useAsyncData } from 'nuxt/app'
import { storeToRefs } from 'pinia'
import useBreadCrumb from '~/composables/useBreadCrumb'
import { CommonLabels, DECIMAL_PLACES, PROJECT_NAME, Units, ValueType } from '~/constants/general'
import { useLithiumOverviewStore } from '~/stores/lithium/overview'
import type { FactboxLithiumPrice } from '~/stores/lithium/definitions'
import { DataSourceRange } from '~/constants/lithium/consts'
import { absNumberFormatter } from '~/utils/numbers'
import { DemandScenarios } from '~/constants/lithium/demand'
import FactBoxTable from '~/components/reports/FactBox/v2/FactBoxTable.vue'
import FactBoxItem from '~/components/reports/FactBox/v2/FactBoxItem.vue'
import type { FactBoxItemProps } from '~/components/reports/FactBox/v2/definitions'
import { generateTooltipPriceTable } from '~/components/reports/FactBox/v2/utils'
import { getVariationClassColor, getVariationStyle } from '~/utils'
import { useGeneralStore } from '~/stores/general'
import { useCacheControl } from '~/composables/cacheControl'

const { removeAllRequestCached } = useCacheControl()
const generalStore = useGeneralStore()
const { setWeightedUnweighted } = generalStore
const { weightedUnweighted } = storeToRefs(generalStore)

const lithiumOverviewStore = useLithiumOverviewStore()
const {
  fetchFactboxData,
  getSupplyAndDemandData,
  getBalanceData,
  getChemicalSupplyAndDemandProduct,
} = lithiumOverviewStore

const {
  supplyAndDemandMined,
  supplyAndDemandChemical,
  balanceMined,
  factboxDataV1,
  factboxDataV2,
  balanceChemical,
  supplyAndDemandChemicalProduct,
} = storeToRefs(lithiumOverviewStore)
const { breadcrumbData, breadCrumbObject, configBreadCrumb } = useBreadCrumb()
const disclaimer =
  'the balance is indicative only, the choice of chemical output is somewhat flexible. "contact an analyst" for more details'

const subtitle = ref('Overview')
const activeTab = ref()
const supplyAndDemandTitle = ref('Lithium Market Supply and Demand')
const balanceTitle = ref('Lithium Market Balance')
const balanceOptions = ref({
  mined: null,
  chemical: null,
})
const tabsLinksData = ref([
  { name: 'Mined', link: 'mined', icon: 'menu/raw-material', type: 'mined' },
  { name: 'Chemical', link: 'chemical', icon: 'menu/chemical', type: 'chemical' },
])
const units = ref({
  tpa: Units.TPA,
})

const selectedScenario = ref(DemandScenarios.baseDemand)

const demandScenarios = computed(() => Object.values(DemandScenarios))

const priceRangeFactbox = computed(() => {
  if (!factboxDataV2.value) return

  const { priceRange } = factboxDataV2.value

  const [namesHeaderDef, ...valuesHeadersDef] = priceRange.headers

  const headers = [
    {
      ...namesHeaderDef,
      classes: 'text-sm font-semibold text-white',
    },
    ...valuesHeadersDef.map((item, index) => {
      return {
        ...item,
        columnClasses: 'text-p-yellow-500',
        classes: (data: FactboxLithiumPrice, columnDef: string) =>
          `text-sm font-semibold ${getVariationClassColor(data[columnDef].variation)}`,
        onShowTooltip: (data: FactboxLithiumPrice) => ({
          id: `tooltip-fact-box-id-${data.name.replaceAll(' ', '-')}-${index}`,
          content: generateTooltipPriceTable(data),
        }),
        formater: (value: FactboxLithiumPrice, columnDef: string) =>
          `${value[columnDef].value} (${absNumberFormatter(
            value[columnDef].variation,
            DECIMAL_PLACES,
          )}${Units.PERCENTAGE})`,
      }
    }),
  ]

  return {
    headers,
    value: priceRange.value,
  }
})

const settingsFactboxV2 = computed((): FactBoxItemProps[] => {
  if (!factboxDataV2.value) return []

  const { hydroxideVsCarbonate, lithiumCarbonateGlobal } = factboxDataV2.value

  return [
    {
      ...hydroxideVsCarbonate,
      labelClass: 'text-left',
    },
    {
      label: lithiumCarbonateGlobal.label,
      labelClass: 'text-left',
      value: `${lithiumCarbonateGlobal.value.price} (${absNumberFormatter(
        lithiumCarbonateGlobal.value.variation,
        DECIMAL_PLACES,
      )}${Units.PERCENTAGE})`,
      valueClass: getVariationStyle(lithiumCarbonateGlobal.value.variation),
    },
  ]
})

const settingsFactboxV1 = computed((): FactBoxItemProps[] => {
  if (!factboxDataV1.value) return []

  const [chemicalProduction, minedProduction, ...others] = factboxDataV1.value.slice(
    2,
    factboxDataV1.value.length,
  )

  return [minedProduction, chemicalProduction, ...others].map(({ label, value, isWeight }) => ({
    label: label.split('<br>'),
    value,
    labelClass: 'text-white text-left',
    valueClass: 'text-p-yellow-500',
    isWeighted: !!isWeight,
  }))
})

const hasFactbox = computed(
  () => settingsFactboxV1.value.length > 0 && settingsFactboxV2.value.length > 0,
)

const selectedChildTab = ref('market')
const childTabs = ref(['market', 'product'])

const startYear = ref(DataSourceRange.START_YEAR)
const endYear = ref(DataSourceRange.END_YEAR)

const onChangeStartYear = (year: number) => {
  startYear.value = year
}

const onChangeEndYear = (year: number) => {
  endYear.value = year
}

const onChangeToggleWeightedUnweighted = (value: ValueType) => {
  setWeightedUnweighted(value)
}

const switchActiveChildTab = (tab) => {
  selectedChildTab.value = tab
}

const changeSelectedScenario = async (event: Event) => {
  const isChecked = (event.target as HTMLInputElement)?.checked
  if (isChecked) {
    const selectedScenarioKey = (event.target as HTMLInputElement).value
    selectedScenario.value = DemandScenarios[selectedScenarioKey]
    pending.value = true
    await Promise.all(fetch(activeTab.value.type))
    pending.value = false
  }
}

const switchActiveTab = async (tab) => {
  pending.value = true
  selectedChildTab.value = 'market'
  activeTab.value = tab
  await Promise.all(fetch(tab.type))
  pending.value = false
}

const fetchBalanceData = async (tab) => {
  await getBalanceData(tab, selectedScenario.value.key)

  const chartData =
    activeTab.value.type === 'mined'
      ? balanceMined.value[selectedScenario.value.key]
      : balanceChemical.value[selectedScenario.value.key]

  if (!chartData) return null

  const type = activeTab.value.type === 'mined' ? 'Mine' : 'Chemical'
  balanceOptions.value[tab] = {
    key: activeTab.value.type + selectedScenario.value.key,
    chartData: chartData.demand,
    name: subtitle.value,
    title: balanceTitle.value,
    tooltipPostfix: units.value[chartData.unit],
    yScaleLabel: 'tpa, LCE',
    palette: 'lithiumOverviewBalance',
    type,
    units: units.value[chartData.unit],
    sourceData: {
      quarter: chartData.quarter,
      year: chartData.year,
    },
  }
}

const fetchSupplyAndDemandData = async (tab) => {
  await Promise.all([
    getChemicalSupplyAndDemandProduct(selectedScenario.value.key),
    getSupplyAndDemandData(tab, selectedScenario.value.key),
  ])
}

const marketSupplyFilteredData = computed(() => {
  if (!supplyAndDemandChartData.value) return

  const data = structuredClone(toRaw(supplyAndDemandChartData.value.chartData))

  return data.map((item) => {
    return {
      ...item,
      data: item.data.filter(
        (dataItem) =>
          Number(dataItem.name) >= startYear.value && Number(dataItem.name) <= endYear.value,
      ),
    }
  })
})

const balanceFilteredData = (data) => {
  if (!data) return null

  return data.map((item) => {
    return {
      ...item,
      data: item.data.filter(
        (dataItem) =>
          Number(dataItem.name) >= startYear.value && Number(dataItem.name) <= endYear.value,
      ),
    }
  })
}

const makeSupplyAndDemandChartData = (data, activeTabType, palette, childTabType = '') => {
  const type = activeTabType === 'mined' ? 'Mine' : 'Chemical'

  if (!data) return null

  const sourceData = {
    quarter: data.quarter,
    year: data.year,
  }

  return {
    key:
      activeTabType + childTabType + startYear.value + endYear.value + selectedScenario.value.key,
    chartData: data.demand,
    name: subtitle.value,
    title: supplyAndDemandTitle.value,
    tooltipPostfix: units.value.tpa,
    yScaleLabel: CommonLabels.TONNES_LCE,
    palette,
    type,
    hasWeightProperty: data.hasWeightedProperty,
    isWeight: data.isWeighted,
    sourceData,
  }
}

const supplyAndDemandChartData = computed(() => {
  if (activeTab.value.type === 'mined')
    return makeSupplyAndDemandChartData(
      supplyAndDemandMined.value[selectedScenario.value.key],
      activeTab.value.type,
      'lithiumSupplyAndDemandMined',
    )

  if (activeTab.value.type === 'chemical') {
    if (selectedChildTab.value === 'market') {
      return makeSupplyAndDemandChartData(
        supplyAndDemandChemical.value[selectedScenario.value.key],
        activeTab.value.type,
        'lithiumSupplyAndDemandChemical',
        selectedChildTab.value,
      )
    }

    if (selectedChildTab.value === 'product') {
      return makeSupplyAndDemandChartData(
        supplyAndDemandChemicalProduct.value[selectedScenario.value.key],
        activeTab.value.type,
        'lithiumSupplyAndDemandChemicalProduct',
        selectedChildTab.value,
      )
    }
  }

  return null
})

const fetch = (tab) => [fetchSupplyAndDemandData(tab), fetchBalanceData(tab)]

const getFullData = async () => {
  const promises = tabsLinksData.value
    .map((tab) => {
      return [
        fetchBalanceData(tab.type),
        getSupplyAndDemandData(tab.type, selectedScenario.value.key),
      ]
    })
    .flat()
  promises.push(getChemicalSupplyAndDemandProduct(selectedScenario.value.key))
  promises.push(fetchFactboxData())

  await Promise.all(promises)
}

const setBreadCrumb = () => {
  breadCrumbObject.value = { prevItems: ['Lithium', 'Overview', activeTab.value.name] }
  configBreadCrumb(true)
}

const { pending } = useAsyncData(async () => {
  const defaultTab = tabsLinksData.value[0]

  const route = useRoute()
  activeTab.value = route.params.tab
    ? tabsLinksData.value.find((tab) => tab.type === route.params.tab)
    : defaultTab
  setBreadCrumb()

  await getFullData()
})

watch(() => activeTab.value, setBreadCrumb)

watch(
  () => weightedUnweighted.value,
  async () => {
    pending.value = true
    removeAllRequestCached()
    await getFullData()
    pending.value = false
  },
)

useHead({
  title: `${PROJECT_NAME} | Lithium | Overview`,
})
</script>

<style scoped>
.custom-tabs {
  left: 55%;
}

@media only screen and (width <=1220px) {
  .custom-tabs {
    left: 60%;
  }
}

@media only screen and (width <=1132px) {
  .custom-tabs {
    left: 70%;
  }
}

.card-background {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)),
    theme('colors.p-blue.50');
}
</style>
