import { DateFormats } from '~/constants/general'
import type { FactboxLithiumPrice } from '~/stores/lithium/definitions'

export const generateTooltipPriceTable = (factbox: FactboxLithiumPrice): HTMLElement => {
  const div = document.createElement('div')
  div.id = `tooltip-id-weighted-unweighted-content`
  div.style.zIndex = '91'
  div.setAttribute('data-testid', 'tooltip-weighted-unweighted')

  const spanTitle = document.createElement('span')
  spanTitle.setAttribute('data-testid', 'title-tooltip')
  spanTitle.style.fontWeight = '700'
  spanTitle.textContent = 'Lithium-bearing'

  const currentDate = reformat(factbox.date, DateFormats.dd_MMM_YY, true)
  const lastChangeDate = reformat(factbox.lastChange, DateFormats.dd_MMM_YY, true)

  const content = `Price as at <b>1600 GMT</b> - <b>${currentDate}.</b><br/>
  Percentage change from <br/>
  <b>1600 GMT</b> - <b>${lastChangeDate} to ${currentDate}.</b>`
  div.innerHTML = content

  return div
}
