<template>
  <StandardizedContainer
    :card-test-attribute="ident"
    :title="title"
    :name="name"
    :chart-id="ident"
    :wrapper-id="`${ident}-wrapper`"
    :csv-payload="csvPayload"
    :loading="loading"
    :error="error"
    :order-of-headers="orderOfBalanceHeaders"
    :toggle-size="toggleSize"
    :source-data="sourceData"
    download-version="v2"
    :download-template-id="`balance-download-template-${ident}`"
    @expanded="onExpandContainer"
  >
    <div class="chartAreaWrapper">
      <div :id="`benchmark-chart-${ident}`">
        <base-bar-chart
          v-if="!loading"
          ref="chart"
          :class="isExpandedContainer ? 'h-full-screen' : 'geography-chart'"
          v-bind="$attrs"
          :chart-data="reactiveChartData"
          :chart-id="ident"
          :ident="ident"
          :tooltip-postfix="units"
          :y-scale-label="yScaleLabel"
          :show-legend="false"
          :is-stacked="chartData.length > 1 && !lineChart"
          :has-custom-tooltip="hasCustomTooltip"
        />
      </div>
    </div>
    <template v-if="canShowToggles" #containerToggles>
      <standardize-toggle
        :datasets="chartPayload.datasets"
        :chart-id="ident"
        :toggles-center="false"
        :index-hidden="indexHidden"
        @update-dataset="updateDataset"
      ></standardize-toggle>
    </template>
    <template v-if="downloadVersion === 'v2'" #downloadTemplate>
      <DownloadLayoutTemplate
        :template-id="`balance-download-template-${ident}`"
        :title="formattedTitle"
        :toggles="canShowToggles ? chartPayload.datasets : null"
        :index-hidden="indexHidden"
        :footer-text="`Benchmark Mineral Intelligence ${sourceData?.year || ''} ${
          sourceData?.quarter || ''
        }`"
      >
        <base-bar-chart
          ref="chart"
          :class="isExpandedContainer ? 'h-full-screen' : 'geography-chart min-h-[700px]'"
          v-bind="$attrs"
          :chart-data="reactiveChartData"
          :chart-id="ident"
          :ident="ident"
          :tooltip-postfix="units"
          :y-scale-label="yScaleLabel"
          :show-legend="false"
          :is-stacked="chartData.length > 1 && !lineChart"
          :has-custom-tooltip="hasCustomTooltip"
          :animation="false"
        />
      </DownloadLayoutTemplate>
    </template>
  </StandardizedContainer>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import makeIdentString from '~/utils/makeIdentString'
import capitalize from '~/utils/capitalize'
import xlsxFormatNumber from '~/utils/chartData/xlsxFormatNumber'
import { getObjectToNumberXLSX } from '~/utils/chartData/sheetjs.util'
import useColors from '~/composables/useColors'
import useExpandContainer from '~/composables/useExpandContainer'

const { isExpandedContainer, onExpandContainer } = useExpandContainer()
const { colors } = useColors()
const route = useRoute()

const props = withDefaults(
  defineProps<{
    title?: string
    name?: string
    chartData?: any[]
    summary?: any[]
    hasCustomTooltip?: boolean
    units?: string
    type?: string
    palette?: string
    lineChart?: boolean
    yScaleLabel?: string
    showToggles?: boolean
    toggleSize?: string
    sourceData?: {
      quarter: number
      year: string
    }
    downloadVersion?: 'v1' | 'v2'
  }>(),
  {
    title: '',
    name: '',
    chartData: () => [],
    summary: () => [],
    hasCustomTooltip: false,
    units: '',
    type: 'Region',
    palette: '',
    lineChart: false,
    yScaleLabel: '',
    showToggles: null,
    toggleSize: 'large',
    sourceData: null,
  },
)

const chartPayload = ref({})
const csvPayload = ref([])
const orderOfBalanceHeaders = ref([])
const loading = ref(true)
const error = ref(null)

const ident = computed(() => makeIdentString(props.title))
const canShowToggles = computed(() => chartPayload.value && chartPayload.value.datasets?.length > 0)
const { reactiveChartData, indexHidden, updateDataset } = useFilterDatasets(chartPayload)

const formattedTitle = computed(() => {
  const product = route.path.split('/')[1]
  return getTitleByProduct({ name: props.name, view: props.type, chartId: ident.value, product })
})

onMounted(() => {
  if (props.chartData.length) {
    chartPayload.value = {
      labels: props.chartData[0].data.map((year) => year.name),
      datasets: [...props.chartData].reverse().map((item, index) => {
        const type = props.lineChart ? 'line' : 'bar'
        const color = props.palette
          ? colors[props.palette][item.name]
          : [...colors.regions].reverse()[index % 6]
        return {
          type,
          label: item.name,
          backgroundColor: color,
          borderColor: color,
          data: item.data.map((year) => year.data),
        }
      }),
    }
    reactiveChartData.value = JSON.parse(JSON.stringify(chartPayload.value))

    // CSV DATA START
    const formatNumber = xlsxFormatNumber(props.units)
    props.chartData.forEach((item, index) => {
      if (index === 0) {
        orderOfBalanceHeaders.value.push(capitalize(props.type))
      }
      const yearsAsRowBalance = {}
      item.data.forEach((year) => {
        if (index === 0) {
          orderOfBalanceHeaders.value.push(year.name.toString())
        }
        yearsAsRowBalance[year.name.toString()] = getObjectToNumberXLSX(year.data, formatNumber)
      })

      const csvRowBalance = {
        [capitalize(props.type)]: item.name,
        ...yearsAsRowBalance,
      }
      csvPayload.value.push(csvRowBalance)
    })
    // CSV DATA END
  }

  loading.value = false
})
</script>

<style scoped>
.geography-chart {
  height: 385px;
}

.container-toggles::-webkit-scrollbar {
  @apply w-1;
}
</style>
